<template>
  <div>
    <b-row>
      <b-col md="12">
        <a
          @click="$router.back()"
          href="#"
          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <!--        <b-row class="justify-content-between">-->
        <!--          <b-col cols="auto">-->
        <!--            <h3 class="text-dark-75 font-weight-bolder text-hover-primary mb-1">-->
        <!--              Detail Ujian-->
        <!--            </h3>-->
        <!--          </b-col>-->
        <!--          <b-col cols="auto">-->

        <!--          </b-col>-->
        <!--        </b-row>-->
        <div class="card card-custom card-stretch">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Pembobotan Ujian
              </span>
            </h3>
            <div class="card-toolbar">
              <span
                @click="exportRekapNilai"
                class="btn btn-primary font-weight-bolder font-size-sm mr-1"
              >
                Export Nilai Akhir
              </span>
              <span
                @click="saveBobot"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Simpan</span
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless card-table"
              :filter="filter"
              :current-page="currentPage"
              :per-page="perPage"
              :items="currentRekapNilaiList"
              :fields="fields"
              :busy="isLoading"
              @filtered="onFiltered"
              show-empty
              responsive
            >
              <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
              <template #empty="scope">
                <h6 class="text-center">Tidak ada nilai ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada nilai ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #cell(select)="data">
                <label class="checkbox checkbox-lg checkbox-single">
                  <input
                    type="checkbox"
                    :value="data.item.id"
                    v-model="data.item.checked"
                    @input="onChangeRow"
                  />
                  <span></span>
                </label>
              </template>
              <template #head(select)="data">
                <label class="checkbox checkbox-lg checkbox-single">
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @input="onChangeAll"
                  />
                  <span></span>
                </label>
              </template>
              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Deskripsi:
                </div>
                <div v-html="row.item.deskripsi"></div>
              </template>
              <template #cell(kode_ujian)="data">
                {{ data.item.kode_ujian }}
              </template>
              <template #cell(nama_ujian)="data">
                {{ data.item.nama }}
              </template>
              <template #cell(bobot)="data">
                <!--                <validation-provider-->
                <!--                    #default="{ errors }"-->
                <!--                    name="Bobot"-->
                <!--                    rules="required"-->
                <!--                >-->
                <b-input-group append="%">
                  <!--                    :state="errors.length > 0 ? false:null"-->
                  <b-form-input
                    id="input-1"
                    v-model="data.item.pembobotan.bobot"
                    type="number"
                    placeholder="Bobot"
                  ></b-form-input>
                </b-input-group>
                <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
                <!--                </validation-provider>-->
              </template>
              <template #cell(action)="data">
                <span
                  @click="data.toggleDetails"
                  class="custom-v-dropdown btn btn-light-info btn-icon mr-1"
                >
                  <!--                  {{ data.detailsShowing ?'Sembunyikan':'Lihat Deskripsi' }}-->
                  <i
                    :class="`ki ki-bold-arrow-${
                      data.detailsShowing ? 'up' : 'down mt-1'
                    } icon-sm`"
                  ></i>
                </span>
                <router-link
                  :to="`/rekap-nilai/${selectedMapel}/${data.item.id}`"
                  class="btn btn-light-primary font-weight-bolder font-size-sm mr-1"
                  >Lihat Nilai Siswa</router-link
                >
                <!--                <span-->
                <!--                    class="btn btn-icon btn-info btn-hover-info shadow-sm mr-1"-->
                <!--                    v-b-tooltip.hover.right="'Lihat Soal Lengkap'"-->
                <!--                    @click="data.toggleDetails">-->
                <!--&lt;!&ndash;                  {{ row.detailsShowing ? 'Hide' : 'Show'}} Details&ndash;&gt;-->
                <!--                  <i :class="`ki ki-bold-arrow-${data.detailsShowing ?'up':'down'} icon-sm`"></i>-->
                <!--                </span>-->
                <!--                <span-->
                <!--                      @click="viewJawaban(data.item)"-->
                <!--                      class="btn btn-icon btn-primary btn-hover-primary shadow-sm mr-1"-->
                <!--                      v-b-tooltip.hover.right="'Lihat Pilihan Jawaban'"-->
                <!--                  >-->
                <!--                    <i class="ki ki-bold-arrow-next icon-sm"></i>-->
                <!--                </span>-->
                <!--                <b-dropdown-->
                <!--                    variant="light"-->
                <!--                    toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"-->
                <!--                    no-caret-->
                <!--                    right-->
                <!--                    no-flip-->
                <!--                >-->
                <!--                  <template v-slot:button-content>-->
                <!--                    <i class="ki ki-bold-more-hor"></i>-->
                <!--                  </template>-->
                <!--                  &lt;!&ndash;begin::Navigation&ndash;&gt;-->
                <!--                  <div class="navi navi-hover min-w-md-250px">-->
                <!--                    <b-dropdown-text tag="div" class="navi-item">-->
                <!--                      <router-link class="navi-link" :to="`/bank-soal/${selectedMapel}/soal/${data.item.id}/edit`">-->
                <!--                        <span class="navi-icon">-->
                <!--                          <i class="flaticon2-edit"></i>-->
                <!--                        </span>-->
                <!--                        <span class="navi-text">Edit Soal</span>-->
                <!--                      </router-link>-->
                <!--                    </b-dropdown-text>-->
                <!--                    <b-dropdown-text tag="div" class="navi-item">-->
                <!--                      <span class="navi-link cursor-pointer" @click="deleteSoal(data.item)">-->
                <!--                        <span class="navi-icon">-->
                <!--                          <i class="flaticon2-trash"></i>-->
                <!--                        </span>-->
                <!--                        <span class="navi-text">Hapus Soal</span>-->
                <!--                      </span>-->
                <!--                    </b-dropdown-text>-->
                <!--                  </div>-->
                <!--                  &lt;!&ndash;end::Navigation&ndash;&gt;-->
                <!--                </b-dropdown>-->
                <!--                <router-link-->
                <!--                    :to="`/bank-soal/${selectedMapel}/soal/${data.item.id}/edit`"-->
                <!--                    class="btn btn-icon btn-warning btn-hover-warning shadow-sm mr-1"-->
                <!--                    v-b-tooltip.hover.right="'Edit soal'"-->
                <!--                >-->
                <!--                  <i class="mdi mdi-pencil"></i>-->
                <!--                </router-link>-->
                <!--                <span-->
                <!--                    @click="deleteSoal(data.item)"-->
                <!--                    class="btn btn-icon btn-danger btn-hover-danger shadow-sm mr-1"-->
                <!--                    v-b-tooltip.hover.right="'Hapus soal'"-->
                <!--                >-->
                <!--                    <i class="mdi mdi-trash-can"></i>-->
                <!--                  </span>-->
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
        <!--        <b-card header-tag="header" footer-tag="footer">-->
        <!--          <template v-slot:header>-->
        <!--            <b-row>-->
        <!--              <b-col>-->
        <!--                <h6 class="mb-0">Daftar Soal</h6>-->
        <!--                <b-skeleton width="25%" v-if="isLoading"></b-skeleton>-->
        <!--                <span class="mb-0" v-if="!isLoading && currentSoalList[0]">{{ currentSoalList[0].ujian.nama }}</span>-->
        <!--              </b-col>-->
        <!--              <b-col cols="auto">-->
        <!--                <b-button pill variant="warning" class="mr-1" @click="$router.back()">Kembali</b-button>-->
        <!--                <b-button pill variant="primary" tag="router-link" :to="`/mapel/${idKurikulum}/ujian/${idUjian}/soal/create`">Tambah</b-button>-->
        <!--              </b-col>-->
        <!--            </b-row>-->
        <!--          </template>-->

        <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
        <!--        </b-card>-->
      </b-col>
      <!--      <b-col md="4">-->
      <!--        <div class="card card-custom card-stretch">-->
      <!--          <div class="card-body pt-4">-->

      <!--          </div>-->
      <!--        </div>-->
      <!--      </b-col>-->
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";
import { GET_MAPEL } from "@/core/services/store/mapel.module";
import {
  EXPORT_REKAP_NILAI,
  GET_REKAP_NILAI,
  UPDATE_REKAP_NILAI,
} from "@/core/services/store/rekapnilai.module";
export default {
  name: "RekapNilaiList",
  data() {
    return {
      isLoading: false,
      selectedMapel: null,
      modalJawaban: false,
      selectedSoal: {},
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      filter: null,
      selectAll: false,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      fields: [
        {
          key: "select",
          sortable: false,
        },
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_ujian",
          label: "Kode Ujian",
          sortable: true,
        },
        {
          key: "nama_ujian",
          label: "Nama Ujian",
          sortable: true,
        },
        {
          key: "bobot",
          label: "Bobot",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Rekap Nilai" }]);
    this.getMapel();
  },
  computed: {
    ...mapState({
      errors: (state) => state.soal.errors,
    }),
    ...mapGetters(["currentRekapNilaiList", "currentMapelList"]),
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    options() {
      var opt = [];
      this.currentMapelList.forEach((mapel) => {
        opt.push({
          text:
            mapel.kurikulum.mapel.nama_mapel +
            " - Tingkat " +
            parseInt(mapel.kurikulum.semester / 2 + 1),
          value: mapel.id_kurikulum,
        });
      });
      return opt;
    },
  },
  methods: {
    onChangeAll() {
      this.currentRekapNilaiList.forEach((item, index) => {
        this.$set(
          this.currentRekapNilaiList[index],
          "checked",
          !this.selectAll
        );
      });
    },
    onChangeRow(e) {
      if (!e.target.checked) {
        this.selectAll = false;
      } else {
        var allSelected = this.currentRekapNilaiList.every((val) => {
          return val.checked;
        });
        if (allSelected) {
          this.selectAll = true;
        }
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getMapel() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_MAPEL)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Mata Pelajaran`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;
      // const param = new URLSearchParams()
      // param.append('id_kurikulum', this.selectedMapel)
      this.$store
        .dispatch(GET_REKAP_NILAI, { id_kurikulum: this.selectedMapel })
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentRekapNilaiList.length;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Rekap Nilai`,
            variant: "danger",
            solid: true,
          });
        });
    },
    exportRekapNilai() {
      this.isLoading = true;
      // const param = new URLSearchParams()
      // param.append('id_kurikulum', this.selectedMapel)
      var _data = this.currentRekapNilaiList
        .filter((item) => {
          return item.checked;
        })
        .map((item) => {
          return item.id;
        });

      this.$store
        .dispatch(EXPORT_REKAP_NILAI, {
          id_ujian: _data,
          id_kurikulum: this.selectedMapel,
        })
        .then((response) => {
          this.isLoading = false;
          this.$bvToast.toast("Berhasil Export Rekap Nilai", {
            title: `Berhasil Export Rekap Nilai`,
            variant: "success",
            solid: true,
          });
          // const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement("a");
          // link.href = URL.createObjectURL(blob)
          link.href = response.dir;
          link.download = response.namefile;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Export Rekap Nilai`,
            variant: "danger",
            solid: true,
          });
        });
    },
    saveBobot() {
      this.isLoading = true;
      // const param = new URLSearchParams()
      // param.append('id_kurikulum', this.selectedMapel)
      var _data = this.currentRekapNilaiList
        .filter((item) => {
          return item.pembobotan.bobot;
        })
        .map((item) => {
          return {
            id_ujian: item.id,
            bobot: item.pembobotan.bobot,
          };
        });

      this.$store
        .dispatch(UPDATE_REKAP_NILAI, {
          pembobotan: _data,
          id_kurikulum: this.selectedMapel,
        })
        .then((response) => {
          this.isLoading = false;
          // if(response.status==200){
          this.$bvToast.toast(response.message, {
            title: `Berhasil Menyimpan Bobot Ujian`,
            variant: "success",
            solid: true,
          });
          // }
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Menyimpan Bobot Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style scoped></style>
